import React from 'react';
import cx from 'classnames';

import styles from './Button.module.scss';

export const Button = ({
  children,
  className,
  disabled,
  fullWidth,
  large,
  onClick,
  outline,
  disabledStyle,
  type,
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={cx(
        className,
        styles.button,
        { [styles.buttonFullWidth]: fullWidth },
        { [styles.buttonLarge]: large },
        { [styles.buttonOutline]: outline },
        { [styles.disabled]: disabledStyle },
      )}
    >
      {children}
    </button>
  );
};
