import React from 'react';
import { AlertTriangle } from 'react-feather';

export const FormErrors = ({ errors }) => {
    if (errors.length !== 0) {
        return (
            <div className="basic-form__errors">
                <AlertTriangle className="basic-form__errors__icon" />
                <div>
                    {
                        errors.map((error, i) => {
                            return <p className="basic-form__result-message" key={`error-${i}`}>{error}</p>
                        })
                    }
                </div>
            </div>
        );
    }
    return null;
}
